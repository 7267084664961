var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('h2',{staticClass:"text-h2 mb-2"},[_vm._v(" Адреса доставки ")]),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"7"}},[_vm._l((_vm.addresses),function(addr,i){return _c('div',{key:i,class:_vm.$style.items},[_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"Контактное лицо по адресу доставки (ФИО)","require":""},model:{value:(addr.contact_person),callback:function ($$v) {_vm.$set(addr, "contact_person", $$v)},expression:"addr.contact_person"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_mask),expression:"phone_mask"}],class:_vm.$style.item,attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; },
              function (v) { return _vm.is_correct_phone(v) || _vm.invalid_phone_number; }
            ],"label":"Телефон по адресу доставки","require":""},model:{value:(addr.telephone),callback:function ($$v) {_vm.$set(addr, "telephone", $$v)},expression:"addr.telephone"}})],1),_c('div',{class:_vm.$style.itemsRow},[_c('TextField',{class:[_vm.$style.item, _vm.$style.wide],attrs:{"rules":[
              function (v) { return _vm.is_require(v) || _vm.require_message; }
            ],"label":"Адрес доставки","require":""},model:{value:(addr.address),callback:function ($$v) {_vm.$set(addr, "address", $$v)},expression:"addr.address"}})],1),_c('v-btn',{staticClass:"mb-4",attrs:{"text":"","color":"primary darken-3"},on:{"click":function($event){return _vm.del_addr(i)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete-outline ")]),_c('span',[_vm._v(" Удалить адрес доставки ")])],1),_c('v-divider')],1)}),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.add_addr}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v("mdi-plus")]),_c('span',[_vm._v("Добавить адрес доставки")])],1)],2),_c('v-col',{attrs:{"cols":"7"}},[_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-auto",attrs:{"depressed":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")]),_c('v-btn',{attrs:{"depressed":"","disabled":!_vm.form_valid},on:{"click":_vm.go_back}},[_vm._v(" Назад ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"accent darken-4","depressed":"","type":"submit","disabled":!_vm.form_valid}},[_vm._v(" Продолжить ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }